@import "../../../../styles/variables";

.verify-mobile {
  max-width: 720px;
  margin-top: 140px;
  background-color: #fff;
  &_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.25px;
    color: $inkNormal;
  }
  &_subtitle {
    margin: 32px auto;
    max-width: 560px;
    a {
      color: #0172cb;
    }
  }
  &_tnc {
    font-size: 12px;
    line-height: 16px;
    color: $inkLight;
    cursor: pointer;
  }
}
