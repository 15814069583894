@import "../../styles/variables";

$heightXS: 224px;
$heightSM: 413px;
$heightLG: 580px;
$width: 240px;
$gutter: 20px;
$logoLG: 55px;
$logoXS: 45px;
$inkLight: #5f738c;
$inkPink: #c7417b;

#L4 {
  width: 100px;
  height: 29px;
  margin: auto;
  display: inline-block;
  text-align: center;
}

.none {
  // display: none;
  visibility: hidden;
}

.clear {
  clear: both;
}

.close {
  position: absolute;
  top: -10px;
  left: calc(50% - 15px);
  z-index: 99;
  opacity: 1;
  cursor: pointer;

  img {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 999px;
    background-color: #fff;
  }

  :hover,
  :focus {
    cursor: pointer !important;
  }
}

.front_img {
  position: absolute;
  right: 20px;
  bottom: 20px;
  max-width: 190px;
}

.card_logo {
  // position: absolute;
  // width: 100%;
  // width: 240px;
  height: 160px;
  flex-basis: 240px;
  flex-grow: 0;
  flex-shrink: 0;

  & img {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    // border-top-left-radius: $borderRadius2x;
    object-fit: cover;
  }
}

.hooray {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-block {
  color: $inkNormal;

  & h2,
  h3,
  h4 {
    color: $inkNormal;
  }

  h2 {
    font-size: 16px;
    // margin:0 0 13px 0;
    line-height: 24px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  h2.price {
    font-size: 24px;
    margin: 0 0 10px 0;
    line-height: 28px;
    font-weight: 700;
    display: none;
  }

  h3 {
    border: 1px dashed rgba(255, 255, 255, 0.6);
    display: inline-block;
    padding: 10px 15px;
    margin: 0;
    font-size: 27px;
  }

  .validity {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $inkLight;
  }

  .description {
    margin-top: 22px;
    max-height: 120px;
    min-height: 120px;
    // overflow: scroll;
  }
}

.modal-padding {
  padding: 40px;
}

.grid {
  width: 100%;
  // overflow:auto;
  position: relative;
  border-radius: 4px;
}

.grid-border {
  width: 100%;
  height: 100%;
}

.grid-item {
  // float: left;
  margin-right: 10px;
  margin-bottom: $gutter;
  // background: #fff;
  width: $width;
  // max-width: 258px;
  // height: $heightXS;
  border-radius: $borderRadius2x;
  background-position: center !important;
  background-size: cover !important;

  :hover {
    cursor: pointer;
    //   border: 4px solid green;
  }
}

.equal-height {
  height: $heightXS;
}

.inner-grid {
  // height: 100%;
  // background: #fff;
  border: 8px solid transparent;
  border-radius: 4px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    border: 8px solid rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }

  .card-parent {
    background: #fff;
    border-radius: $borderRadius;
    overflow: hidden;
  }

  .title-block {
    height: 64px;
    padding: 20px 10px;

    h2 {
      margin: 0;
    }
  }
}

.selectedBlock {
  display: block;
  // width: 80%;
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition-duration: 0.4s;
  position: absolute;
  height: 550px;
  width: 720px;
  left: auto;
  top: 0px;
  border-radius: 8px;
  padding: 40px;
  background: #fff;
  &.xoxo-code {
    top: 100px;
    -webkit-transition: -webkit-transform 1s;
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    transition: transform 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .card-parent {
    display: flex;
  }

  :hover {
    cursor: auto;
    transform: none;
  }

  .title-block {
    height: auto;
    padding: 0;
  }
  h2 {
    text-align: left;
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $inkNormal;
  }

  h3 {
    padding: 0;
    //styleName: Heading/Heading 3 - Semibold;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
  }
  .validity {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Ink / Light */

    color: #5f738c;
    margin-bottom: 16px;
  }
  .radio-buttons {
    justify-content: center;
    display: flex;
    flex-direction: row;
  }
  .label {
    color: $inkNormal;
    margin-bottom: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .card_logo {
    width: 240px;
  }

  .front_img {
    max-width: 250px;
  }

  .description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-family: "Inter";
    color: #5f738c;
  }

  hr {
    margin-top: 30px;
  }
}

.column-center {
  display: flex;
  justify-content: center;
}

.blue-link {
  color: #0172cb !important;
}

.collect_voucher_btn {
  position: absolute;
  bottom: 20px;
  width: 90%;
  padding-top: 40px;
  border-top: 1px solid #eee;
  color: white !important;

  .btn {
    height: 56px;
    width: 480px;
    left: 80px;
    top: 0px;
    border-radius: 4px;
    padding: 12px 16px 8px 16px;
    background: $inkNormal;
    color: #fff !important;
  }
}
.divider {
  margin: 30px 0px;
}

.btn {
  border-radius: 4px;
  padding: 12px 16px 8px 16px;
  background: $inkNormal;
  color: #fff;
}

.btn-lg {
  width: 209px;
  padding: 9px 20px;
  font-size: 20px;
  margin: 20px 20px;
}

// .selectedBlock ~ .grid-item{ display: none;}
.text-white {
  color: #fff;
}

.inkNormal {
  color: $inkNormal;
}

.tnc-link {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 4px;
  color: #5f738c !important;
  div {
    display: inline-block;
  }
}

.mt-12 {
  margin-top: 12px !important;
}

.voucher-code {
  // padding: 16px 16px 16px 24px;
  border: 1px dashed #bac7d5;
  box-sizing: border-box;
  color: $inkNormal;
  background: transparent;
  text-align: left;
  border-radius: 4px;
  font-size: 18px;
  margin: 12px 12px;
  width: 480px;
  align-items: center;
  justify-content: center;
  padding: 20px;

  span {
    word-break: break-all;
  }

  &:focus {
    background: transparent;
    color: #fff;
    font-size: 24px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    outline: none;
  }
}

.secure-pin {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $inkNormal;
  margin-bottom: 24px;
}

.copy-icon {
  &:hover {
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.flip1 {
  width: 0%;
  margin: auto;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  font-size: 50px;
  padding: 25px 0;

  color: white;
  overflow: hidden;
  transition: 1s all;
  animation-delay: 2s;
}

.selectedBlock.flip1 {
  width: 100%;
  background: black;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  margin-top: 0;
  left: 0;
  margin-left: 0;
  border-radius: 0;
  background: #0f0;
}

.email-phone {
  display: flex;
  flex-flow: column-reverse;

  span {
    position: absolute;
    left: -23px;
  }

  input:placeholder-shown + label {
    text-align: left;
    cursor: text;
    // max-width: 66.66%;
    // white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
  }

  ::-webkit-input-placeholder {
    // opacity   : 0;
    transition: inherit;
  }

  input:-internal-autofill-selected {
    background-color: transparent;
    background-image: none !important;
  }

  input:focus::-webkit-input-placeholder {
    // opacity   : 1;
    text-align: left;
  }

  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    text-align: left;
  }

  &__flex {
    // display: flex;
    // flex-direction: column;
    // text-align: left;
  }
}

.tnc-block {
  position: relative;
  //  height: 540px;
  z-index: 99999;
  padding: 40px;
  border-radius: 8px;
}

// tnc-cardModal
.tnc-block {
  background-color: #fff;

  perspective: 1000px;
}

.tnc-block-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.tnc-block.show .tnc-block-inner {
  transform: rotateY(180deg);
}

.tnc-block-front,
.tnc-block-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.tnc-block-front {
  background-color: #bbb;
  color: black;
}

.tnc-block-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.cardBlock {
  // width: 100%;
  // height: 100vh;
  position: relative;
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -o-transition: -o-transform 1s;
  transition: transform 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  z-index: 1;
}

.cardBlock .blocks {
  display: block;
  // height: 98vh;
  width: 100%;
  color: white;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1199;
}

.cardBlock .front {
  //   background: red;
}

.absolute {
  position: absolute;
}

.cardBlock .back {
  // background: blue;
  -webkit-transform: rotateY(180deg) translate(50%, 0%);
  -moz-transform: rotateY(180deg) translate(50%, 0%);
  -o-transform: rotateY(180deg) translate(50%, 0%);
  transform: rotateY(180deg) translate(50%, 0%);
  //  height: 544px;
  width: 720px;
  right: -50%;
  position: relative;
}

.cardBlock.flip {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  // .front{
  // 	display: none;
  // }
}

.brand-title {
  margin: auto;
  width: 60%;
  font-size: 20px;
  line-height: 31px;
}

.brand-title ~ p {
  font-size: 20px;
}

.go-to-top {
  display: none;
}

.scrolling .go-to-top {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  border-radius: 999px;
  bottom: 40px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  padding: 8px 24px;
  // position: absolute;
  width: 140px;
  height: 36px;
  background: #000000;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
}

.link {
  text-decoration: none;
  color: $inkLight;
  cursor: pointer !important;

  :hover {
    cursor: pointer !important;
  }
}

.tnc-card {
  color: #333;
  transition: color 1500ms;
  background: #fff;
  border-radius: $borderRadius2x;
  padding: 40px;
  position: relative;
  //   left: 8%;
  //   width: 82%;
  z-index: 999;
  //   position: relative;
  overflow: auto;
  margin-bottom: 20px;

  //   height: 100%;
  .tnc-content {
    height: 310px;
    overflow: auto;
    margin-bottom: 20px;
    font-size: 14px;

    p {
      margin: 0;
      padding: 0;
    }

    h3.text-white {
      margin-bottom: 5px;
    }

    h3.tnc {
      margin-top: 30px;
      //   margin-bottom: 5px;
    }
  }
  .tnc-content-shadow {
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
      0px 25px 50px -5px rgba(0, 0, 0, 0.1);
  }
  .btn-lg {
    position: absolute;
    bottom: 22px;
    left: 37%;
    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: darken($colorDefault, 5%);
      color: white !important;
    }
  }
}

.tnc-cardModal {
  color: $inkNormal;
  height: 310px;
  overflow: auto;
  margin-bottom: 20px;
  font-size: 14px;
  //   margin-bottom: 10px;

  //   transition: color 1500ms;
  // //   background: #100806;
  //   border-radius: 19px;
  //   padding: 10%;
  //   margin: 0 5%;

  //   z-index: 999;
  //   position: relative;
}

/* This fires as soon as the element enters the dorm */
.tnc-card-transition-enter,
.tnc-card-transition-appear {
  /*We give the list the initial dimension of the list button*/
  top: 0%;
  left: 49%;
  width: 0%;
  max-height: 500px;
  color: transparent;
  background-color: #333;
  position: relative;
}

/* This is where we can add the transition*/
.tnc-card-transition-enter-active,
.tnc-card-transition-appear-active {
  left: 0%;
  right: 100%;
  width: 100%;
  max-height: 500px;
  background-color: #333;
  transition: all 400ms;
  position: relative;
}

/* This fires as soon as the this.state.showList is false */
.tnc-card-transition-exit {
  left: 0%;
  width: 100%;
  right: 100%;
  max-height: 500px;
  background-color: #333;
  position: relative;
}

/* fires as element leaves the DOM*/
.tnc-card-transition-exit-active {
  left: 49%;
  width: 0px;
  max-height: 500px;
  color: transparent;
  background-color: #333;
  transition: all 400ms;
  position: relative;
  visibility: hidden;
}

.send_copy {
  color: #eee;
  transition: color 1500ms;
  background: red;
  border-radius: 4px;
  padding: 10%;
  margin: 0 5%;
}

/* This fires as soon as the element enters the dorm */
.send_copy-enter,
.send_copy-appear {
  /*We give the list the initial dimension of the list button*/
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: green;
}

/* This is where we can add the transition*/
.send_copy-enter-active,
.send_copy-appear-active {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: red;
  transition: all 400ms;
}

/* This fires as soon as the this.state.showList is false */
.send_copy-exit {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: red;
}

/* fires as element leaves the DOM*/
.send_copy-exit-active {
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: red;
  transition: all 400ms;
}

@media (min-width: 991px) {
  .height-auto {
    height: calc(100vh - 220px);
  }

  .wrapper-padding {
    padding: 0px 60px;
  }

  .width-19 {
    width: 23.66%;
    height: auto;
  }

  .card4block {
    margin-left: $width/2;

    .selectedBlock {
      left: -5%;
    }
  }
}

@media (min-width: 1300px) {
  .width-19 {
    width: 19%;
  }
}

.tnc-wrap {
  width: 720px;
  height: 400px;
  margin: 0 auto;
  top: 0px;

  .overlay {
    // background: #333;
  }
}

.xc_support {
  backface-visibility: hidden;
  z-index: 0;

  .show-border {
    border-top: 1px solid #757575;
  }

  .color-white {
    color: #ffffff;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: unset;
  }

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.25px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: unset;
    margin: 8px 0px;
  }

  ul {
    list-style-type: disc !important;
    padding-left: 2em !important;

    li {
      list-style: disc;
    }
  }
  &-title {
    filter: invert(100%);
  }
}

.xc_mob_desc {
  overflow-y: auto;
  text-align: left !important;
}

.xc_link {
  border-bottom: #0172cb 1px solid;
}
.close-icon {
  position: absolute;
  top: -18px;
  right: 4px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
}
.class-list-modal {
}
.voucher-modal {
  background: #fff;
  padding: 40px 20px 20px 20px;
  position: relative;
  &-text {
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $inkNormal;
      margin-bottom: 4px;
    }
    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5f738c;
    }
  }
  .modal-footer {
    border-top: 1px solid #dee2e6 !important;
  }
  .moda-header {
    border-bottom: none;
  }
  .modal-content {
    overflow: visible;
  }
  &.modal-dialog-scrollable {
    overflow-y: visible !important;
    .modal-body {
      overflow-y: auto;
      max-height: calc(100vh - 330px) !important;
    }
    .voucher-modal-body {
      @media (max-width: 991px) {
        overflow-y: auto;
        max-height: unset !important;
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 16px !important;
    margin-bottom: 150px;
  }
}
.voucher-modal-shadow {
  background: #ffffff;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
    0px 25px 50px -5px rgba(46, 45, 45, 0.1);
  .close-modal-btn {
    background-color: #fff;
    color: $inkNormal;
    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
}
.modal-text {
  width: 200px;
  padding: 10px 80px !important;
}
.modal-dialog-scrollable {
  overflow-y: auto !important;
  max-height: none !important;
  .modal-content {
    max-height: none !important;
  }
}
.validity {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Ink / Light */

  color: #5f738c;
  margin-bottom: 16px;
}
.radio-buttons {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.label {
  color: $inkNormal;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  /* Ink / Normal */

  color: #252a31;
}
.selected-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  /* Ink / Normal */
  color: #252a31;
}
.enter-info-discription {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  letter-spacing: -0.25px;

  /* Ink / Normal */

  color: #252a31;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;
}
.radio-inputs {
  margin-bottom: 20px;
}

.btn-filled {
  /* Ink / Normal */

  background: #252a31;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  margin: 8px 0;
  svg path {
    fill: #fff;
  }

  &:disabled {
    /* Ink / Normal */
    cursor: not-allowed;
    background: #252a31;
    opacity: 0.6;
    border-radius: 4px;
  }
  &:hover {
    color: white;
    opacity: 0.9;
  }
}

.text-error {
  color: #d21c1c !important;
}
.without-border-bottom {
  border-bottom: none !important;
}
.text-color-ink-light {
  color: $inkLight !important;
}

input:focus {
  background: #ffffff;
  border: 1.5px solid $inkPink !important;
  border-radius: 4px;
}

input[type="radio"] {
  border: 1.5px solid $inkLight !important;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  // box-shadow: inset 1em 1em $inkLight;
}

input[type="radio"]:checked {
  transform: scale(1);
  border: 5.5px solid $inkPink !important;
}

.class-list-modal {
  height: 100% !important;
  padding-bottom: 30px;
}

.pi-input {
  width: 312px;
  height: 36px;
  border-radius: 4px;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.phone-input-field {
  width: auto !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.light-border {
  border: 1px solid #edeff3;
  padding: 24px;
  border-radius: 8px;

  .date {
    color: #4eae8a;
  }
}
