@import "../../styles/variables";

.content {
  background-color: white;
  height: 100vh;
  padding-top: 50px;
}

.box {
  text-align: center;
  background-color: white;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #e8edf1;
  @media screen and (max-width: 991px) {
    width: 90%;
  }
}
.paymentWrapper {
  background-color: lightgrey;
  width: 100%;
  margin-top: 70px;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
  .titleattribute {
    color: black;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
    font-weight: 600;
  }
}
.sub-title {
  color: #5f738c;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.email-title {
  color: #252a31;
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  font-weight: 500;
}
.email-subtext {
  font-size: 14px;
  line-height: 20px;
  color: #5f738c;
  margin-bottom: 25px;
}
.input-email {
  width: 100%;
  height: 40px;
  border: 1px solid $inkLighter;
  color: black;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 2px;
  margin-top: 5px;
  @media screen and (max-width: 991px) {
    width: 90%;
  }
}
.testEmail {
  background-color: #ebebe4;
  cursor: not-allowed;
}

.email-lable {
  color: black;
  @media screen and (max-width: 991px) {
    width: 90%;
  }
}
.submit-Button {
  background-color: #c7417b;
  color: #ffffff;
  width: 150px;
  height: 44px;
  border-radius: 5px;
}
.submit-Button-coloumn {
  margin-top: 20px;
  margin-bottom: 30px;
}
.email {
  text-align: left;
  width: 35%;
  margin: 0 auto;
  @media screen and (max-width: 991px) {
    width: 90%;
  }
}
.poweredby {
  font-size: 14px;
  line-height: 20px;
  font-family: Rubik;
  color: #5f738c;
}
.without-border-bottom-otp {
  border-bottom: none !important;
  .modal-title {
    line-height: 1;
  }
  .heading {
    line-height: 20px;
  }
  .subText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5f738c;
  }
  .back-icon {
    top: -6px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }
}
.claim-btn-filled {
  /* Ink / Normal */

  background: #c7417b;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  margin: 8px 0;
  svg path {
    fill: #fff;
  }

  &:disabled {
    /* Ink / Normal */
    cursor: not-allowed;
    background: #c7417b;
    opacity: 0.6;
    border-radius: 4px;
  }
  &:hover {
    color: white;
    opacity: 0.9;
  }
}
.error {
  color: #970c0c;
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px;
}
