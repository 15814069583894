.howToRedeemInfo {
  color: #252a31;
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translate(0, -50%) !important;
  @media (max-width: 992px) {
    max-width: 375px;
    transform: unset !important;
  }
  .modal-content {
    width: 720px;
    background-color: #fff;
    @media (max-width: 992px) {
      width: 375px;
    }
  }
  .modalTitle {
    padding: 20px 40px;
    button {
      position: relative;
      top: 50%;
    }
    .modal-title {
      font-size: 32px;
      font-weight: 600;
      letter-spacing: -1px;
      @media (max-width: 992px) {
        font-size: 18px;
        letter-spacing: -0.25px;
      }
    }
  }
  .modalBody {
    padding: 20px 40px 60px;
    .redemptionSteps {
      .step {
        color: #252a31;
        .list {
          line-height: 24px;
          .iconBackground {
            height: 26px;
            width: 26px;
            background-color: #252a31;
            border-radius: 50%;
            @media (max-width: 992px) {
              height: 24px;
              width: 24px;
            }
            svg {
              position: relative;
              left: 50%;
              transform: translate(-50%, 0%);
            }
          }
          .title {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.25px;
            margin-bottom: 2px;
            margin-top: 0px;
            @media (max-width: 992px) {
              font-size: 16px;
            }
          }
          .subTitle {
            font-size: 16px;
            @media (max-width: 992px) {
              font-size: 14px;
            }
          }
          .vertical {
            border-left: 1px solid #bac7d5;
            height: 22px;
            width: 0px;
            margin: 12px auto;
            @media (max-width: 992px) {
              height: 18px;
            }
          }
        }
      }
    }
    hr {
      border-top: 1px solid #eeeeee;
    }
    .notesSection {
      .label {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
