.language-switch-popup {
  @media (max-width: 992px) {
    &-container {
      background-color: #00000b8e;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
}

.languages-popup {
  min-width: 290px;
  position: absolute;
  top: 60px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
    0px 25px 50px -5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px;

  &-title {
    padding: 8px 12px;
    font-weight: 500;
    font-size: 12px;
    color: #5f738c;
  }
  &-option {
    padding: 8px 12px;
    font-weight: 400;
    font-size: 16px;
    color: #02020a;
    margin: 4px 0;
    &-selected {
      background-color: #f6f6f7;
    }
    &:hover {
      background-color: #f6f6f7;
      border-radius: 8px;
    }
  }
  @media (max-width: 992px) {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 332px;
    overflow: scroll;
  }
}
