@import "../../../../styles/variables";

.redemption-modal {
  background-color: #fff;
  color: #000;
  &-header {
    border-bottom: 0;
    padding: 7px 0 40px 0;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-body {
    padding: 0px;
  }
  ol {
    padding-inline-start: 20px;
    color: #24292e;
    li {
      margin-bottom: 16px;
    }
  }
  .close {
    position: static;
  }
  .checkbox {
    display: inline-block;
    margin: 34px 0;
    cursor: pointer;
    img {
      margin-right: 14px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6a737d;
    }
  }
  .modal-footer {
    padding: 16px;
    float: right;
    border-top: none !important;
    margin: -20px;
    .col {
      padding: 0;
    }
    .cancel-btn {
      font-size: 14px;
      color: #24292e;
      margin-right: 20px;
    }
    .continue-btn {
      font-weight: 500;
      font-size: 14px;
      color: $colorBackground;
      padding: 8px 16px;
      background-color: $inkNormal;
      border: none;
      &:active {
        background-color: $inkNormal;
      }
      &:disabled {
        background-color: #eef0f2;
        color: #d1d5da;
      }
    }
  }
}
