/* =====================================

	BUTTONS

================================================================================================================ */

.btn {
  padding: 5px 15px;
  border: 1px solid darken($colorPrimary, 5%);
  color: $colorWhite;
  border-radius: $borderRadiusBtn;
  font-size: $fontSizeXS;
  font-weight: $fontWeightMD;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer !important;

  &.btn-outline-default {
    border: 1px solid $inkNormal;
    border-radius: 4px;
    color: $inkNormal;
    padding: 8px 16px;

    &:hover {
      background: $inkNormal;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }

    // background: $inkNormal;
  }

  &.btn-primary {
    border: 1px solid darken($colorPrimary, 5%);
    color: $colorWhite;
    border-radius: 23px;
    font-size: 13px;
    background-color: $colorPrimary;
    border-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: #fff;
      color: $colorPrimary;
      border: 1px solid $colorPrimary;
      box-shadow: none !important;
      cursor: pointer;
    }
  }

  &.btn-secondary {
    border: 1px solid darken($colorSecondary, 5%);
    background-color: $colorSecondary;
    color: $colorWhite;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: darken($colorSecondary, 5%);
    }
  }

  &.btn-default {
    border: 1px solid darken($colorDefault, 5%);
    border-radius: 4px;
    background-color: $inkNormal;
    color: $colorWhite;
    padding: 8px 16px 8px 16px;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: darken($colorDefault, 5%);
    }
  }

  .btn-send {
    width: 80px;
    // height: 36px;
  }

  &.btn-transparent {
    border: none;
    color: $colorText;
    background-color: transparent;

    &.btn-border {
      border: 1px solid $borderColor;
    }

    &.btn-border-primary {
      border: 1px solid $colorPrimary;
      color: $colorPrimary;

      &:hover {
        background: $colorPrimary;
        color: $colorWhite;
      }
    }
  }

  &.btn-lg {
    padding: 10px 20px;
  }

  &.btn-md {
    padding: 6px 20px;
  }

  &.btn-sm {
    padding: 0px 6px;
    font-size: 12px;
  }

  &.btn-xs {
    padding: 2px 4px;
  }

  &.btn-has-icon {
    position: relative;
    top: 10px;
    left: 18px;

    i {
      font-size: 30px;
      position: relative;
      top: 8px;
    }

    &:hover {
      i {
        color: $colorPrimary;
        font-weight: $fontWeightMD;
        right: -5px;
      }
    }
  }

  &.btn-social {
    &.btn-facebook {
      background: #3b5998;
      border-color: darken(#3b5998, 5%);

      .icon {
        border-right: 1px solid lighten(#3b5998, 10%);
      }
    }

    &.btn-google {
      background: #ea4335;
      border-color: darken(#ea4335, 5%);

      .icon {
        border-right: 1px solid lighten(#ea4335, 10%);
      }
    }

    .icon {
      position: absolute;
      width: 50px;
      height: 100%;
      top: 0;
      left: 0;
      color: $colorWhite;
    }
  }
}

.btn-primary[disabled]:hover {
  background: $colorPrimary;
  color: #fff;
}

.btn-wrapper {
  margin: 40px 0;
}

/* =====================================

	FORM INPUTS

================================================================================================================ */

.form-group {
  margin-bottom: 20px;

  .input-error {
    font-size: 12px;
    display: block;
    padding: 1px 5px;
    font-weight: 600;
    color: #f44336;
  }
}

.form-check-input {
  margin-left: 0;
}

.form-control {
  border: 1px solid $borderColorInput;
  border-radius: $inputBorderRadiusSize;
  box-shadow: none !important;
  font-size: $fontSizeSM;
  min-height: 42px;
  margin-right: 0;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $colorPrimary;
  }

  & + {
    .SingleDatePicker {
      position: relative;
      top: -43px;
      width: 98%;
      left: 1%;

      .DateInput--with-caret:before {
        top: 45px;
      }

      .DateInput--with-caret:after {
        top: 46px;
      }

      .SingleDatePicker__picker {
        top: 53px;
        left: -3px;
      }
    }
  }

  &.input-sm {
    min-height: 26px;
  }
}

select.input-lg {
  height: 46px;
  line-height: 1;
}

.rotate {
  transform: rotate(360deg) !important;
}

/***** INPUT *****/
input[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

input[type="checkbox"]:checked:before {
  top: 5px;
  left: -10px;
  width: 18px;
  height: 20px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid $colorPrimary;
  border-bottom: 2px solid $colorPrimary;
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0px;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #a9abae;
  border-radius: 1px;
  margin-top: 0px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
  background: #fff;
}

// .select-dropdown {
// 	position: relative;
//     width: auto;
//     display: inline-block;
//     max-width: 100%;
//     border-radius: 2px;
//     border: 0px solid #ccc;
// 	margin-right: 2px;
// 	&.value{
// 		&:after{
// 			right : 0!important;
// 		}
// 	}
//   select {
// 	max-width: 100%;
// 	font-size: 14px;
// 	min-height: auto;
// 	height: auto;
//     padding: 2px 16px 2px 4px;
//     border: none;
//     background-color: transparent;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     &:active,
//     &:focus {
//       outline: none;
//       box-shadow: none;
//     }
//   }
//   &:after {
// 	content: "\f107 ";
//     font-family: 'FontAwesome';
//     font-size: 14px;
//     position: absolute;
//     top: 50%;
//     margin-top: -11px;
//     right: 12px;
//     width: 0;
//     height: 0;
//   }
// }

.select-dropdown {
  position: relative;
  width: auto;
  display: inline-block;
  max-width: 100%;
  border-radius: 2px;
  border: 0px solid #ccc;
  margin-right: 4px;
  // &.value{
  // 	&:after{
  // 		right : 0!important;
  // 	}
  // }

  select {
    //   margin: 50px;
    width: 100%;
    padding: 2px 15px 0px 1px;
    //   font-size: 16px;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: url("/img/icons/down-arrow.svg") 98% / 11px no-repeat
      transparent;
  }
}

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

/* ---------------------------
	PLACEHOLDERS
------------------------------------------------------------------------------------------------------- */

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  // color: lighten($colorText, 100%);
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  // color: lighten($colorText, 100%);
}

/* ---------------------------
	LABEL
------------------------------------------------------------------------------------------------------- */

label {
  font-weight: $fontWeightMD;
  font-size: $fontSizeXS;
  cursor: pointer;
  margin-left: 5px;
}

.input-title {
  font-size: $fontSizeSM;
  font-weight: $fontWeightMD;
  margin-left: 0;

  &.title-lg {
    font-size: $fontSizeLG;
    font-weight: $fontWeightSX;
  }
}

.input-error {
  font-size: $fontSizeXXS + 1;
  color: $colorDanger;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 0px;
}

//radio Button
input[type="radio"] {
  @include ad_appearance(none);
  @include ad_variableborder(2px, $adradiocolor);
  @include ad_borderRadius(50%);
}

input[type="radio"]:checked {
  @include ad_appearance(none);
  @include ad_variableborder(2px, $adradiocolor);
  @include ad_borderRadius(50%);

  &:focus {
    outline: none;
  }
}

input[type="radio"]:checked:before {
  background: $adradiocolor;
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.float-label {
  input {
    width: 100%;
    height: 58px;
    padding: 16px 16px 0 12px;
    outline: 0;
    border: 1px solid $inkLighter;
    border-radius: 4px;
    background: #fff;
    font-family: $fontFamily;
    font-size: 14px;
    color: $inkNormal;
  }

  label {
    font-size: 16px;
    font-family: $fontFamily;
    padding: 0 12px;
    color: $inkLighter;
    pointer-events: none;
    position: absolute;
    top: -10px;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    font-weight: 400;
  }

  &:focus-within label {
    transform: translate(0, 17px) scale(0.8);
    color: $inkLight;
  }

  .active {
    transform: translate(0, 17px) scale(0.8);
    // color: $colorPrimary;
  }

  .input-send-copy {
    width: 480px;
    height: 58px;
    border: 1px solid $inkLighter;
  }
}

///// checkbox

$md-checkbox-checked-color: $colorPrimary;
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 0.5em 0;
$md-checkbox-size: 1.25em;
$md-checkbox-padding: 0.25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.125em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 0.5em;

.md-checkbox {
  position: relative;
  margin: $md-checkbox-margin;
  text-align: left;

  &.md-checkbox-inline {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: inline;
    line-height: $md-checkbox-size;
    vertical-align: top;
    clear: both;
    padding-left: 1px;

    &:not(:empty) {
      padding-left: $md-checkbox-label-padding;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 4px;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: $md-checkbox-border-width solid $borderGrey;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      transition: background 0.3s;
    }

    &:after {
      // checkmark
    }
  }

  input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;

    &:checked {
      + label:before {
        background: $md-checkbox-checked-color;
        border: none;
      }

      + label:after {
        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate(
            $md-checkbox-padding,
            ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)
          )
          rotate(-45deg);
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }

    &:disabled {
      + label:before {
        border-color: $colorDefault;
      }

      &:checked {
        + label:before {
          background: $md-checkbox-checked-color-disabled;
        }
      }
    }
  }
}

// float_label
.voucher {
  &__block {
    @media (min-width: 992px) {
      width: 75% !important;
      margin: 0 auto;
      justify-content: center !important;
    }
  }
  &__block2 {
    @media (min-width: 992px) {
      width: 100% !important;
      margin: 0 auto;
      justify-content: left !important;
    }
  }

  &__input_block {
  }

  &__input input {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: $inkNormal;
    border: 1px solid $inkLighter;
    border-radius: 4px;
    padding: 7px 15px;

    @media (max-width: 992px) {
      font-size: 12px;
    }

    &:focus {
      border-color: #252a31;
    }
  }
}

.-label {
  cursor: auto !important;
  display: block;
  margin-bottom: 0 !important;
}

.css-1uccc91-singleValue {
  @media (max-width: 992px) {
    font-size: 11px !important;
  }
}

.css-tlfecz-indicatorContainer {
  @media (max-width: 992px) {
    padding: 3px !important;
  }

  svg {
    @media (max-width: 992px) {
      width: 16px !important;
    }
  }
}

.input-group-append {
  height: 34px;

  .input-group-text {
    padding: 8px 12px 8px 8px;
    border: unset;
    font-size: 14px;
    background-color: transparent;
    color: $inkLight;

    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
}

.inputError {
  border: 1px solid #d21c1c;
  background-color: #faeaea;
}

.-countryCode {
  text-align: left !important;
  width: 32%;
  color: $inkNormal;
  :focus {
    border: none !important;
  }
  @media (max-width: 992px) {
    width: 50%;
  }
}

.inputErrorOnly {
  border: 1px solid #bac7d5;
  color: $inkNormal;
  min-height: 40px;
  height: 40px;
  border: unset;
  color: $inkNormal;
  font-size: 14px;

  @media (max-width: 992px) {
    font-size: 12px;
    min-height: 34px;
    height: 34px;
  }

  &::-webkit-input-placeholder {
    color: $inkLight;
  }

  &::-moz-placeholder {
    color: $inkLight;
  }

  :-ms-input-placeholder {
    color: $inkLight;
  }

  :-moz-placeholder {
    color: $inkLight;
  }

  &.error {
    border: 1px solid #d21c1c;
    background-color: #faeaea;
  }
}

.-phnError {
  color: #d21c1c;
  text-align: left;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 22px;
  }
}

.css-1m09ebu-control,
.css-djwo0a-control {
  min-height: 40px !important;
  height: 40px !important;

  @media (max-width: 992px) {
    min-height: 34px !important;
    height: 34px !important;
  }
}

.css-1m09ebu-control:focus {
  box-shadow: none !important;
  border: 1px solid black;
}
