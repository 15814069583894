$colorPrimary: #66286b;

$colorDanger: #f44336;

$colorDefault: #8e8e8e;

$colorSecondary: #55c0c9;

$colorText: #646464; //#484848;

$colorBackground: #ffffff;

$adradiocolor: #9744a2;

$colorWhite: #ffffff;

$colorgray: #808080;

$borderGrey: #707070;

$colorDark: #000000;

$xoxodayorange: #f15c22;

/*---------------------------
|  cardGradient
-------------------*/
$gradientprimarydark: rgba(99, 38, 103, 1);

$gradientprimarylight: rgba(159, 73, 171, 1);

/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */

$fontSizeXXS: 12px;

$fontSizeXS: 14px;

$fontSizeSM: 16px; //14

$fontSizeMD: 17px;

$fontSizeLG: 21px;

$fontSizeXL: 24px;

$fontSizeXXL: 36px;

$fontFamily: "Inter", sans-serif;

/***** Line Height ****/

$lineHieghtXXS: 18px;

$lineHieghtXS: 19px;

$lineHieghtSM: 21px;

$lineHieghtMD: 24px;

$lineHieghtLG: 28px;

/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */

$fontWeightSX: 300;

$fontWeightSM: 400;

$fontWeightMD: 500;

$fontWeightLG: 600;

$fontWeightXL: 700;

/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */

$borderRadiusSize: 7px;
$borderRadiusBtn: 18px;
// $boxShadowBtn		: 0px 3px 6px #00000029;
$inputBorderRadiusSize: 2px;

$borderColor: #e3e3e3;
$borderColorInput: darken($borderColor, 5%);

$boxShadowHuge: 0px 0 20px 5px hsla(0, 0%, 82%, 0.38);

$borderRadius: 4px;
$borderRadius2x: 8px;
$lineHeight: 24px;
$inkNormal: #252a31;
$inkLight: #5f738c;
$inkLighter: #bac7d5;
$fontSize: 16px;
