input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #eee;
  width: 90%;
  color: #fff;
  font-size: 22px;
  padding: 15px 5px 10px 5px;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    // color:#eee;
    color: #5f738c;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    // color:#eee;
    color: #5f738c;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    // color:#eee;
    color: #5f738c;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.navbar {
  padding: 0px 70px 0 50px;
  height: 100px;
  position: sticky;
  top: 0;
  align-self: flex-start;
  flex-wrap: wrap;
  /* <-- this is the fix */
  z-index: 99999;
  z-index: 1051;
  width: 100%;
  transition: all 0.1s ease;

  @media (max-width: 992px) {
    text-align: center;
    height: auto;
  }
}

.navbar-brand {
  max-height: 64px;
  padding: 0;

  img {
    max-width: 100%;
    max-height: 64px;
  }
  @media (max-width: 992px) {
    width: 100%;
    margin-right: 0;
  }
}

.header-line {
  width: 100vw;
  border-bottom: 3px solid;
  backdrop-filter: blur(20px);
  position: sticky;
  top: 100px;
  z-index: 1050;
  @media screen and (max-width: 991px) {
    top: 80px;
  }
}
.navbar-menu {
  display: flex;
  gap: 12px;
  @media (max-width: 992px) {
    margin-top: 19px;
    width: 100%;
    gap: 8px;
  }
  &-dark {
    color: #000;
    img {
      filter: invert(1);
    }
  }
  &-light {
    color: white;
  }
}

.scrolled {
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(20px);
  .navbar-menu {
    &-dark {
      color: #fff;
      img {
        filter: invert(0);
      }
    }
  }
}

.navbar-options {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 20px;
  background: rgba(217, 213, 213, 0.2);
  border-radius: 60px;
  white-space: nowrap;

  span {
    font-weight: 600;
  }
  @media (max-width: 992px) {
    font-size: 12px;
    padding: 8px 20px;
  }
  &:nth-child(2) {
    width: 100%;
  }
  &-onlyxoxocode {
    background: #f15c22;
    span,
    img {
      filter: invert(0);
    }
  }
}
.navbar-language {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  img:nth-child(1) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  img:nth-child(3) {
    margin-left: 10px;
  }
  &-arrow-flip {
    transform: rotate(180deg);
  }
}

.navbar-nonmultilang {
  &.navbar-background {
    @media (max-width: 992px) {
      height: 81px;
    }
  }
  .navbar-brand {
    @media (max-width: 992px) {
      width: auto;
    }
  }
  .navbar-menu {
    justify-content: center;
    @media (max-width: 992px) {
      margin-top: 0px;
      width: auto;
    }
  }
}
.navbar-validity {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
}

.button-footer {
  padding-top: 20px;
  margin-top: 20px;
}

.vshow {
  visibility: initial;
}

.vhide {
  visibility: hidden;
}

// ONLY PROJECT

.Toastify__toast-container.Toastify__toast-container--top-center {
  width: 35%;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: left;

  @media (max-width: 992px) {
    width: 90%;
  }
}

.Toastify__toast--success {
  background: #2b7336 !important;
  border-radius: 5px !important;
}

.Toastify__toast-body {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
  padding-left: 36px;

  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 22px;
  }

  &::before {
    content: "✓";
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    background-color: white;
    color: #2b7336;
    padding: 2px 6px;
    left: 0;
    border-radius: 50%;
    font-weight: 900;
  }
}

.Toastify__toast {
  margin-bottom: 0 !important;
  min-height: 100% !important;
  padding: 12px 12px !important;
}

.Toastify__close-button {
  display: none !important;
}
