.landingPage {
  height: 100vh;
  .redeemBtn {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    line-height: 24px;
    &.mobile {
      font-size: 14px;
    }
  }
  .howToLink {
    font-size: 16px;
    cursor: pointer;
    color: #0172cb;
    &:hover {
      text-decoration: underline;
    }
  }
  .landingDescription {
    font-size: 24px;
    color: #fff;
    line-height: 32px;
    &.mobile {
      font-size: 14px;
    }
  }
}
