@import "../../../styles/_variables.scss";
.footer {
  background-color: #f7f7f7;
  color: #707070;
  padding: 50px 30px 10px;

  ul {
    list-style: none;
    line-height: 40px;
    h3 {
      font-size: 16px;
      color: $colorPrimary;
    }
    li {
      font-size: 14px;
      color: #707070;
      a {
        &:hover {
          color: $colorPrimary;
        }
      }
    }
  }
  .social {
    li {
      display: inline;
      margin: 5px;
    }
  }
}
.copy-right {
  background-color: #f7f7f7;
  color: #707070;
}
.purple {
  color: $colorPrimary;
}

.chatbotfooter {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  background-color: #f6f7f9;
  padding: 16px;
  width: 100%;
  color: #4c5a70;
  a {
    color: #1d61f6;
    text-decoration: none;
  }
  img {
    width: 16px;
    margin-right: 8px;
  }
}
