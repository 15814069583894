.simpleLayout {
  overflow-y: auto;
  .backGroundImg {
    background-size: cover !important;
  }
  .previewBody {
    background-color: #fff;
    border-radius: 8px;
    margin: 0 15%;
    top: 50%;
    transform: translate(0%, -50%);
    max-height: 75%;
    overflow-y: auto;
    scrollbar-width: 2px;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &.mobile {
      margin: 0 20px;
      width: unset;
    }
    .logoHeader {
      padding: 28px 0px;
      text-align: center;
      background-color: #f8f8f8;
      border-radius: 8px 8px 0px 0px;
      position: sticky;
      top: 0px;
      img {
        width: 120px;
        max-height: 40px;
        max-width: 120px;
        object-fit: contain;
      }
      &.mobile {
        padding: 12px 0px;
        img {
          width: 96px;
        }
      }
    }
    .content {
      padding: 20px 20px 40px;
      height: unset !important;
      .layoutTitle {
        font-size: 40px;
        font-family: "Manrope", sans-serif;
        line-height: 55px;
        letter-spacing: 0.5px;
        &.mobile {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
