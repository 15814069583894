h1,
h2,
h3,
h4 {
  font-weight: $fontWeightMD;
  color: $inkNormal;
}
h1 {
  font-size: $fontSizeXXL;
  line-height: $lineHieghtLG;
}
h2 {
  font-size: $fontSizeXL;
  line-height: $lineHieghtMD;
  margin-top: 1rem;
}
h3 {
  font-size: 16px;
  line-height: 31px;
}
h4 {
  font-size: $fontSizeMD;
  line-height: $lineHieghtXS;
}
h5 {
  font-size: $fontSizeSM;
}

p {
  line-height: $lineHieghtMD;
}
span {
  &.primary {
    color: $colorPrimary;
  }
}
a {
  &.primary {
    color: $colorPrimary;
  }
}

a {
  color: $colorgray;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: $colorgray;
  }
}

.ink-lighter {
  color: $inkLighter;
}
.inkLight {
  color: $inkLight;
}
.inkLighter {
  color: $inkLighter;
}
.inkNormal {
  color: $inkNormal;
}
.colorDark {
  color: $colorDark;
}

.text-lg {
  font-size: $fontSizeLG;
}

.title {
  color: $colorDark;
}

.bodyText {
  color: $colorText;
}

strong {
  font-weight: $fontWeightMD;
}

.brand-color {
  color: $colorPrimary;
}
.text-black {
  color: #000000;
}
.text-color {
  color: #fff;
}

.link {
  color: $colorPrimary;
  cursor: pointer;
  // text-decoration: underline;
  // &:hover {
  // 	text-decoration: underline;
  // }
}
ul {
  padding-left: 0px;
  li {
    list-style: none;
  }
}
.cursor-pointer {
  cursor: pointer;
}
