@mixin clear() {
  &::after {
    clear: both;
    display: block;
    content: "";
    height: 0.9px;
    visibility: hidden;
  }
}

/* ---------------------------
	TRANSITIONS
------------------------------------------------------------------------------------------------------- */

@mixin transition($prop: all, $time: 0.2s, $ease: linear, $delay: 0.1s) {
  -webkit-transition: $prop $time $ease $delay;
  -moz-transition: $prop $time $ease $delay;
  -o-transition: $prop $time $ease $delay;
  -ms-transition: $prop $time $ease $delay;
  transition: $prop $time $ease $delay;
}

@mixin transitionCubic($prop: all) {
  -webkit-transition: $prop 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  -moz-transition: $prop 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  -o-transition: $prop 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  -ms-transition: $prop 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: $prop 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

@mixin transitionAll() {
  -webkit-transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  -moz-transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  -o-transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  -ms-transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}
@mixin overlayfull {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
  -ms-transition: background 0.5s;
  transition: background 0.5s;
}
@mixin transformCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin ad_borderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin ad_variableborder($size, $_color) {
  -webkit-border: $size solid $_color;
  -moz-border: $size solid $_color;
  -ms-border: $size solid $_color;
  border: $size solid $_color;
}

@mixin ad_appearance($_appearance) {
  -webkit-appearance: $_appearance;
  -moz-appearance: $_appearance;
  appearance: $_appearance;
}

@mixin cardGradient($color1, $color2) {
  background: -webkit-linear-gradient(to right, $color1, $color2);
  background: -moz-linear-gradient(to right, $color1, $color2);
  background: -ms-linear-gradient(to right, $color1, $color2);
  background: linear-gradient(to right, $color1, $color2);
}
