body {
  font-size: $fontSizeSM;
  color: $colorText;
  background-color: rgb(32, 31, 56);
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding-right: 0;
  line-height: $lineHeight;
  overflow-y: scroll;
  position: relative;
}

.showOneline,
.showOneLine span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.showTwoline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.width-50 {
  width: 50%;
}

.border-radius {
  border-radius: $borderRadiusSize;
}

.box-shadow {
  box-shadow: $boxShadowHuge;
}

.svg-icon {
  width: 16px;
  margin-right: 10px;
  margin-top: 10px;
}
.svg-white {
  filter: brightness(0) invert(1);
}
